

























@import '~@/assets/styles/helpers/_variables.scss';

.v-icon { color: $colorError; }
